<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm :title="formTitle"
                       in-progress-message="Creating Virtual Institute, Please wait..."
                       success-message="Virtual Institute Created."
                       :action-text="actionText"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="createInstitue"
                       :hide-action="hideEdit"
                       @close="$refs.sideBar.emitToggle()">
      <div>
        <div class="has-text-centered has-text-weight-bold guru-registration-info" v-if="isAdd">
          You need to create a virtual Institute before creating courses/assignments
        </div>
        <DoodleInput field-name="code" label-text="Virtual Institute Code (3-10 AlphaNumeric Characters)" type="text"
                     leftIcon="envelope"
                     v-model="doodleForm.code" :model="doodleForm.code"
                     rule="required|min:3|max:10|regex:^[a-zA-Z0-9]*$" place-holder="XYZSCHOOL" :disabled="!isAdd"/>
        <DoodleInput field-name="name" label-text="Virtual Institute Name" type="text"
                     left-icon="address-card"
                     rule="required|min:5|max:255" :model="doodleForm.name" :disabled="isView"
                     v-model="doodleForm.name" place-holder="XYZ School"/>
        <DoodleInput field-name="firstName" label-text="Your First Name" type="text"
                     left-icon="lock"
                     rule="required|min:3|max:255" :model="doodleForm.firstName" :disabled="isView"
                     v-model="doodleForm.firstName" place-holder="" reff="newPassword" v-show="!hideEdit"/>
        <DoodleInput field-name="lastName" label-text="Your Last Name" type="text"
                     left-icon="lock"
                     rule="required|min:3|max:255" :model="doodleForm.lastName" :disabled="isView"
                     v-model="doodleForm.lastName" place-holder="" v-show="!hideEdit"/>
        <DoodleTextArea field-name="description" label-text="Description" :disabled="isView"
                        :model="doodleForm.description" place-holder="Short description about your institute."
                        v-model="doodleForm.description" rule="required|min:5|max:10000" rows="5"/>
      </div>
      <template v-slot:successMessage>
        <div class="has-text-centered">
          <div class="register-thanks" v-if="isAdd">
            Thanks for Signing up!!!
          </div>
          <div class="register-thanks" v-if="isEdit">
            Updated Successfully!!!
          </div>
        </div>
      </template>
    </DoodleGeneralForm>
    <div v-if="isView" class="guru-hints">
      <span class="has-text-weight-bold is-underlined">Hints:</span> Only 'Account Owner' can edit this page.
    </div>
  </SideBar>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import DoodleInput from '../Utils/DoodleInput'
import DoodleGeneralForm from '../Utils/DoodleGeneralForm'
import SideBar from '../Utils/SideBar'
import { eventBus } from './../../assets/javascript/event-bus'
import DoodleTextArea from '../Utils/DoodleTextArea'
import { GURU_EVENTS } from '../../assets/javascript/constants'

export default {
  name: 'guruRegister',
  mixins: [formMixin],
  inject: ['$validator'],
  components: { DoodleTextArea, SideBar, DoodleGeneralForm, DoodleInput },
  props: ['mode', 'isAccountOwner', 'institute'],
  data: function () {
    return {
      doodleForm: {
        name: null,
        code: null,
        firstName: null,
        lastName: null,
        description: null
      },
      editMode: false
    }
  },
  mounted () {
    if (this.localMode === 'view') {
      if (this.isAccountOwner) {
        this.getInsDetails()
      } else {
        Object.assign(this.doodleForm, this.institute)
      }
    }
  },
  methods: {
    createInstitue () {
      if (this.isView) {
        this.editMode = true
        return
      }

      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/subscribe',
        data:
        {
          'instituteCode': this.doodleForm.code,
          'instituteName': this.doodleForm.name,
          'description': this.doodleForm.description,
          'firstName': this.doodleForm.firstName,
          'lastName': this.doodleForm.lastName,
          'mode': this.localMode
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          if (this.isAdd) {
            eventBus.$emit(GURU_EVENTS.GURU_INIT)
          }
        },
        jdaEvent: (this.isAdd) ? 'guru-signup' : 'guru-ins-update',
        jdaLabel: ''
      })
    },
    getInsDetails () {
      this.executeAPIWitoutValiation({
        url: '/api/guru-api/instituteDetailsForOwner',
        method: 'post',
        data: {
          'instituteCode': this.institute.code
        },
        successAction: (data) => {
          Object.assign(this.doodleForm, data.instituteOwn)
        },
        markCompleted: false,
        form: this.doodleForm
      })
    }
  },
  computed: {
    formTitle () {
      let titlePrefix = 'Your'

      if (this.localMode === 'add') {
        titlePrefix = 'Create'
      } else if (this.localMode === 'edit') {
        titlePrefix = 'Update'
      }

      return titlePrefix + ' Virtual Institute'
    },

    actionText () {
      let titlePrefix = 'Edit'

      if (this.localMode === 'add') {
        titlePrefix = 'Create'
      } else if (this.localMode === 'edit') {
        titlePrefix = 'Update'
      }

      return titlePrefix + ' Institute'
    },
    localMode () {
      if (this.editMode) {
        return 'edit'
      }

      return this.mode
    },
    isAdd () {
      return this.localMode === 'add'
    },
    isEdit () {
      return this.localMode === 'edit'
    },
    isView () {
      return this.localMode === 'view'
    },
    hideEdit () {
      return this.isView && !this.isAccountOwner
    }
  }
}
</script>

<style scoped lang="scss">
  .guru-registration-info {
    margin-bottom: 10px;
    color: green;
  }

  .ins-details {
    padding: 5px;
  }
</style>
